<template>
    <client-page>
        <template #subHead>
            <sub-visual sh="태성 이혼가사센터" tabActive="변호사 소개" bg="/images/sub/visual/sv-about.jpg" />
        </template>
        <page-section tit="변호사 소개" titAdd="법무법인 태성">

            <v-row class="row--lg">
                <v-col v-for="(item, key) in lawyer" :key="key" cols="6" md="4">
                    <v-row class="row--xxs">
                        <v-col>
                            <router-link :to="'/taesung/lawyer/' + key" class="lawyer-item">
                                <v-img :src="'/images/lawyer/list/'+item.title+'.jpg'" :aspect-ratio="420 / 630" max-width="420" class="w-100" />
                                <div class="lawyer-item__contents pa-16px pa-md-30px pa-lg-40px">
                                    <txt class="white--text font-weight-bold line-height-1 font-secondary mb-4px mb-md-8px">
                                        {{item.titleEn}}
                                    </txt>
                                    <v-row justify="center" align="center" class="row--xxs">
                                        <v-col cols="12" sm="auto">
                                            <tit class="tit--sm white--text">{{item.title}}</tit>
                                        </v-col>
                                        <v-col cols="12" sm="auto">
                                            <txt class="white--text"><span v-if="item.ceo">대표 </span>변호사</txt>
                                        </v-col>
                                    </v-row>
                                    <icon-arrow-primary size="x-large" class="white--text mt-16px mt-md-32px"/>
                                </div>
                            </router-link>
                        </v-col>
                        <v-col cols="auto">
                            <p class="font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase" >Lawfirm Taesung</p>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import { lawyer } from "@/assets/data/lawyer.js";
import ClientPage from "@/sets/styles/apps/client-page.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/sets/styles/pages/page-section.vue";
import IconArrowPrimary from "@/components/publish/parents/icons/icon-arrow-primary.vue";

import Tit from "@/components/publish/styles/typography/tit.vue";
import Txt from "@/components/publish/styles/typography/txt.vue";

export default {
    props: {},
    components: {
        ClientPage,
        SubVisual,
        PageSection,
        IconArrowPrimary,

        Tit,
        Txt,
    },
    data() {
        return { 
            lawyer,
        }
    },
};
</script>

<style lang="scss" scoped>
.lawyer-item{
    display: block;
    position: relative;
    &__contents{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        background-color: rgba(177, 138, 0, .7);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        transition: .15s ease-out;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .lawyer-item:hover{
        .lawyer-item__contents{
            background-color: var(--v-primary-base);
        }
    }
}
@media (min-width:1200px){
}

</style>
