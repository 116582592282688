var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "about-card-group row--lg",
    attrs: {
      "align": "start"
    }
  }, _vm._l(_vm.about, function (item, index) {
    return _c('v-col', {
      key: index,
      staticClass: "about-card-col",
      attrs: {
        "cols": "6"
      }
    }, [_c('div', {
      staticClass: "about-card-wrap",
      attrs: {
        "data-aos": item.aos,
        "data-aos-delay": item.aosDelay
      }
    }, [index % 2 !== 0 ? _c('p', {
      staticClass: "about-card__caption d-none d-md-block font-size-12 vertical-rl line-height-1 letter-spacing-5 grey--text text--lighten-1 font-secondary text-uppercase"
    }, [_vm._v("Lawfirm Taesung")]) : _vm._e(), _c('router-link', {
      staticClass: "about-card",
      attrs: {
        "to": item.link
      }
    }, [_c('v-card', {
      staticClass: "about-card__thumb v-card--none-active-bg transparent",
      attrs: {
        "rounded": "sm"
      }
    }, [_c('v-img', {
      staticClass: "about-card__thumb__thumb w-100",
      attrs: {
        "src": item.image,
        "max-width": "690",
        "aspect-ratio": 690 / 460
      }
    }), _c('div', {
      staticClass: "about-card__active pa-16px"
    }, [_c('txt', {
      staticClass: "primary--text line-height-1 font-secondary font-weight-bold mb-4px mb-md-8px"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c('tit', {
      staticClass: "white--text tit--sm mb-16px mb-md-32px"
    }, [_vm._v(_vm._s(item.title))]), _c('icon-arrow-primary', {
      staticClass: "white--text",
      attrs: {
        "size": "x-large"
      }
    })], 1)], 1), _c('div', {
      staticClass: "about-card__contents pt-16px pt-md-32px d-none d-lg-block"
    }, [_c('txt', {
      staticClass: "txt--light line-height-1 font-secondary font-weight-bold mb-4px mb-md-8px"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c('tit', {
      staticClass: "white--text tit--sm"
    }, [_vm._v(_vm._s(item.title))])], 1)], 1)], 1)]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }